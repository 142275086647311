/* Helvetica */

@font-face{
	font-family:"Helvetica Neue Con";
	font-weight: normal;
	src:url("../Fonts/easyscan/c6b63be6-6438-4c5e-8bda-0aabe7846d50.eot?#iefix");
	src:url("../Fonts/easyscan/c6b63be6-6438-4c5e-8bda-0aabe7846d50.eot?#iefix") format("eot"),url("../Fonts/easyscan/9516ef74-4fe9-45fe-a09f-493cb9518f71.woff2") format("woff2"),url("../Fonts/easyscan/5b99bc59-1b2e-446b-961e-2e1dbdfcd641.woff") format("woff"),url("../Fonts/easyscan/1eb34627-d796-4531-9e56-d58a15603fcd.ttf") format("truetype");
}
@font-face{
	font-family:"Helvetica Neue Con";
	font-weight: bold;
	src:url("../Fonts/easyscan/960546c0-8bc0-4951-94f4-a871c16e8211.eot?#iefix");
	src:url("../Fonts/easyscan/960546c0-8bc0-4951-94f4-a871c16e8211.eot?#iefix") format("eot"),url("../Fonts/easyscan/02980b3d-1e37-4378-a5c6-4cf0d6ce0ba5.woff2") format("woff2"),url("../Fonts/easyscan/2d1e8a5f-eb7a-47a1-be1d-878bb9a46b6e.woff") format("woff"),url("../Fonts/easyscan/54838aa7-3cb7-478e-b2a6-14eef3033f22.ttf") format("truetype");
}
@font-face{
	font-family:"Helvetica Neue Ext";
	font-weight: normal;
	src:url("../Fonts/easyscan/cf946728-155f-4279-a1a6-ac3ec0d40428.eot?#iefix");
	src:url("../Fonts/easyscan/cf946728-155f-4279-a1a6-ac3ec0d40428.eot?#iefix") format("eot"),url("../Fonts/easyscan/2f270d4e-e43c-457e-ba8a-0dd33bea037a.woff2") format("woff2"),url("../Fonts/easyscan/4595f8b8-db8c-4c01-a7a4-9bb031810849.woff") format("woff"),url("../Fonts/easyscan/a4766f92-fbbb-40e8-a519-88748a601f0b.ttf") format("truetype");
}
@font-face{
	font-family:"Helvetica Neue Ext";
	font-weight: bold;
	src:url("../Fonts/easyscan/2a74a765-9048-4fbf-940e-0688f56805cf.eot?#iefix");
	src:url("../Fonts/easyscan/2a74a765-9048-4fbf-940e-0688f56805cf.eot?#iefix") format("eot"),url("../Fonts/easyscan/37cade8a-4499-42b4-b79f-99143dd3a764.woff2") format("woff2"),url("../Fonts/easyscan/6823dde3-d344-4bb4-b5a4-add12527140d.woff") format("woff"),url("../Fonts/easyscan/c2716483-3920-4b2e-a62b-844db9e36d97.ttf") format("truetype");
}

/* Tioem */

@font-face {
	font-family: 'Tioem-Handwritten';
	src: url('../Fonts/easyscan/Tioem-Handwritten.eot');
	src: url('../Fonts/easyscan/Tioem-Handwritten.eot?#iefix') format('embedded-opentype'),
	url('../Fonts/easyscan/Tioem-Handwritten.woff2') format('woff2'),
	url('../Fonts/easyscan/Tioem-Handwritten.woff') format('woff'),
	url('../Fonts/easyscan/Tioem-Handwritten.ttf') format('truetype'),
	url('../Fonts/easyscan/Tioem-Handwritten.svg#Tioem-Handwritten') format('svg');
}
