nav.footer {
	ul {
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		justify-content: space-between;
		margin: rem-calc(20 0);
		list-style: none;
		font-weight: bold;

		@include breakpoint(medium) {
			flex-direction: row;
			margin: rem-calc(10 0);
		}

		li {
			text-align: center;

			@include breakpoint(medium) {
				margin-left: rem-calc(30);

				&:first-child {
					margin-left: 0;
					margin-right: auto;
				}
			}
		}

		a {
			display: block;
			padding: rem-calc(10 0);
			color: $white;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
