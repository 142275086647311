.partner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	padding: rem-calc(15 0);
	margin: rem-calc(0 -7);

	img {
		width: calc(100% / 3);
		padding: rem-calc(5 7);

		@include breakpoint(medium) {
			width: calc(100% / 6);
		}

		@include breakpoint(large) {
			width: calc(100% / 12);
		}
	}
}
