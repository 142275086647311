$global-width: rem-calc(1290);

$body-font-family: 'Helvetica Neue Con', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$header-font-family: 'Helvetica Neue Con', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$ext-font-family: 'Helvetica Neue Ext', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$handwritten-font-family: Tioem-Handwritten, serif;

$grid-container: $global-width;
$grid-margin-gutters: (
	small: 20px,
	medium: 30px
);
$grid-padding-gutters: $grid-margin-gutters;
$grid-container-padding: (
	small: 40px,
	medium: 40px
);
$grid-container-max: $global-width;
