.logos-container {
	position: relative;
}

.logos {
	position: absolute;
	top: rem-calc(-16);
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	width: 100%;

	@include breakpoint(medium) {
		top: rem-calc(-20);
		flex-direction: row;
		align-items: stretch;
	}

	@include breakpoint(large) {
		top: rem-calc(-45);
	}

	.logo-dentalunion {
		width: 100%;
		max-width: rem-calc(90);
		padding: rem-calc(15);
		margin-bottom: rem-calc(3);
		background: $primary-color;

		@include breakpoint(medium) {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			width: 38%;
			max-width: 100%;
			margin-bottom: 0;
			margin-right: rem-calc(5);
		}

		@include breakpoint(large) {
			padding: rem-calc(23);
		}

		@include breakpoint(xlarge) {
			padding: rem-calc(33);
		}
	}

	.logo-easyscan {
		width: 100%;
		max-width: rem-calc(90);

		@include breakpoint(medium) {
			width: 62%;
			max-width: 100%;
		}
	}

	img {
		width: 100%;
		height: auto;
	}
}
