/*
 * Headlines
 */

h1, h2, h3 {
	line-height: 1.2;
	color: $primary-color;
}

h1 {
	font-size: rem-calc(16);

	@include breakpoint(medium) {
		font-size: rem-calc(20);
	}

	@include breakpoint(large) {
		font-size: rem-calc(28);
	}
}

h2 {
	margin-bottom: rem-calc(20);
	font-size: rem-calc(20);

	@include breakpoint(medium) {
		font-size: rem-calc(28);
	}

	@include breakpoint(large) {
		margin-bottom: rem-calc(30);
		font-size: rem-calc(32);
	}
}

h3 {
	margin-bottom: rem-calc(10);
	font-size: rem-calc(16);

	@include breakpoint(large) {
		margin-bottom: rem-calc(15);
		font-size: rem-calc(24);
	}
}

h1.slogan {
	margin: 0;
	font-weight: normal;
	font-family: $ext-font-family;
	color: $primary-color;

	span {
		display: block;

		@include breakpoint(medium) {
			display: inline;
		}
	}

	span.space {
		display: none;
		color: $white;

		@include breakpoint(medium) {
			display: inline;
		}
	}

	span:last-child {
		color: $white;
	}
}

h3.handwritten {
	margin-top: rem-calc(-9);
	font-size: rem-calc(28);

	@include breakpoint(medium) {
		font-size: rem-calc(34);
	}

	@include breakpoint(large) {
		font-size: rem-calc(42);
	}
}

/*
 * Bodytext
 */

p, ul, ol {
	margin-bottom: rem-calc(20);
	font-size: rem-calc(16);
	line-height: 1.5;

	@include breakpoint(large) {
		margin-bottom: rem-calc(30);
		font-size: rem-calc(18);
	}
}


/*
 * Helper
 */

.handwritten {
	font-family: $handwritten-font-family;
	line-height: 1;
	font-weight: normal;
}
