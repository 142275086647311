.usp {
	ul {
		margin: rem-calc(30) 0 0 0;
		padding: 0;
		list-style: none;
		text-align: center;

		li {
			margin-bottom: rem-calc(30);
			font-size: rem-calc(14);

			@include breakpoint(large) {
				margin-bottom: rem-calc(40);
				font-size: rem-calc(18);
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		svg {
			display: block;
			width: rem-calc(35);
			max-height: rem-calc(35);
			margin: 0 auto rem-calc(10) auto;
			color: $white;

			@include breakpoint(large) {
				width: rem-calc(45);
				max-height: rem-calc(45);
			}
		}
	}

	img {
		display: block;
		width: 100%;
		max-width: rem-calc(180);
		height: auto;
		margin: rem-calc(20) auto rem-calc(-50) auto;

		@include breakpoint(medium) {
			margin: rem-calc(30) auto 0 auto;
		}

		@include breakpoint(large) {
			max-width: rem-calc(340);
			margin: rem-calc(40) auto rem-calc(-100) auto;
		}
	}
}
