body {
	background: $light-gray;
}

.page {
	width: 100%;
	max-width: rem-calc(1920);
	margin: 0 auto;
	background: $white;
}

header.main {
	position: relative;
	padding: rem-calc(16 0);
	margin-bottom: rem-calc(30);
	background: $primary-color-light;

	@include breakpoint(medium) {
		padding: rem-calc(20 0);
		margin-bottom: rem-calc(40);
	}

	@include breakpoint(large) {
		padding: rem-calc(45 0);
		margin-bottom: rem-calc(70);
	}

	.grid-container {
		margin-bottom: 0;
	}
}

section.video {
	margin-bottom: rem-calc(50);

	.responsive-embed {
		margin-bottom: rem-calc(30);
	}
}

section.usp {
	padding: rem-calc(10 0);
	color: $primary-color;
	background: $primary-color-light;

	@include breakpoint(large) {
		padding: rem-calc(30 0 42 0);
	}
}

nav.footer {
	color: $white;
	background: $secondary-color;

	.grid-container {
		margin-bottom: 0;
	}
}

footer.main {
	.grid-container {
		margin-bottom: 0;
	}
}
