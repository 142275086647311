.button {
	width: 100%;
	padding-left: rem-calc(20);
	padding-right: rem-calc(20);
	font-weight: bold;
	font-size: rem-calc(16);

	&:hover {
		background: $secondary-color;
	}

	@include breakpoint(medium) {
		width: auto;
	}

	@include breakpoint(large) {
		padding-left: rem-calc(40);
		padding-right: rem-calc(40);
		font-size: rem-calc(18);
	}
}
